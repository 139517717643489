//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import RightArrow from '@/assets/svg/short-arrow-right.svg?inline'
import LeftArrow from '@/assets/svg/short-arrow-left.svg?inline'
import FoldArrow from '@/assets/svg/button-arrow-next.svg?inline'
import ButtonArrow from '@/assets/svg/button-arrow.svg?inline'
export default {
  components: { RightArrow, ButtonArrow, LeftArrow, FoldArrow },
  props: {
    banners: {
      type: Array,
      required: true
    },
    toggleUnfold: {
      type: Function,
      required: false
    },
    unfolded: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      currentBanner: 0,
      folded: false,
      foldedDesktop: false
    }
  },
  created () {
    this.folded = this.$store.state.foldedBanner
    console.log(this.$store.state.foldedBanner)
  },
  computed: {
    isDesktop () {
      const windowWidth = window.innerWidth
      const lgSize = 1240

      return windowWidth > lgSize
    },
    isMobile () {
      console.log(window.innerWidth, 380, window.innerWidth < 380)
      const windowWidth = window.innerWidth
      const smSize = 768

      return windowWidth < smSize
    }
  },
  methods: {
    toggle (value) {
      this.folded = value
      this.toggleUnfold(value)
    },
    increase () {
      if (this.currentBanner === this.banners.length - 1) {
        this.currentBanner = 0
        return
      }
      this.currentBanner += 1
    },
    decrease () {
      if (this.currentBanner === 0) {
        this.currentBanner = this.banners.length - 1
        return
      }
      this.currentBanner -= 1
    }
  }
}
