import { render, staticRenderFns } from "./default.vue?vue&type=template&id=6d145df6&scoped=true&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&id=6d145df6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d145df6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Announcements: require('/app/components/Announcements.vue').default,Header: require('/app/components/Header.vue').default,TheFooter: require('/app/components/screens/TheFooter.vue').default})
