import { endOfYear, isWithinInterval, isFirstDayOfMonth, isLastDayOfMonth, startOfMonth, endOfMonth, format, startOfDay } from 'date-fns'
import ru from 'date-fns/locale/ru'

const monthNames = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
  'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'
]

const CURRENT_SEASON = 2023

export function getSeason (events) {
  const seasons = events.map(event => event.season)
  const latestSeasonYear = Math.max(...seasons)

  return latestSeasonYear || CURRENT_SEASON
}

export const getPluralMonthName = (date, fullName) => {
  return `${format(date, fullName ? 'MMMM' : 'MMM', { locale: ru })}`
}

const getDates = function (startDate, endDate) {
  const dates = []
  let currentDate = startDate
  const addDays = function (days) {
    const date = new Date(this.valueOf())
    date.setDate(date.getDate() + days)
    return date
  }
  while (currentDate <= endDate) {
    dates.push(currentDate)
    currentDate = addDays.call(currentDate, 1)
  }
  return dates
}

export const generateDatesWithEvents = (events) => {
  const season = getSeason(events)

  // берем предыдущий год
  const prevYear = new Date()

  prevYear.setFullYear(season - 1)
  // дата от начала декабря предыдущего года
  const a = startOfMonth(endOfYear(prevYear))
  // дата до конца ноября текущего года
  const nextYear = new Date()
  nextYear.setFullYear(season)
  const b = endOfMonth(nextYear.setMonth(10))
  // получаем даты в массивы
  const dates = getDates(a, b)
  // формируем более информативный объект с датами
  const objectDates = dates.map((el) => {
    return {
      date: el,
      active: false,
      isStart: false,
      isSub: false,
      event: null
    }
  })

  events.forEach((event) => {
    objectDates.forEach((el, idx) => {
      // проверяем есть ли текущий день между датами какого либо эвента
      const isBetween = isWithinInterval(
        el.date,
        { start: event.startDate, end: event.endDate }
      )

      if (startOfDay(new Date()).getTime() === el.date.getTime()) {
        el.isToday = true
      }

      if (isBetween) {
        // если этот день уже содержит в себе событие и мы заново попадаем сюда из другого, то ставим флаг isSub
        if (el.event) {
          el.isSub = true
        } else {
          el.event = event
        }

        // проставляем активный день и эвент для вывода в тултип
        el.active = true

        // доавляем рендж дат на русском языке
        if (el.event.startDate.getMonth() === el.event.endDate.getMonth()) {
          el.datesRange = `${new Date(el.event.startDate).getUTCDate() + 1} - ${new Date(el.event.endDate).getUTCDate()} ${getPluralMonthName(el.event.endDate)}`
        } else {
          el.datesRange = `${new Date(el.event.startDate).getUTCDate() + 1} ${getPluralMonthName(el.event.startDate)} - ${new Date(el.event.endDate).getUTCDate()} ${getPluralMonthName(el.event.endDate)}`
        }
      }

      // если день является стартовым в каком либо эвенте ставим флаг и от него строим тултип
      if (event.startDate.getTime() === el.date.getTime()) {
        // const subDays = getDates(el.event.startDate, el.event.endDate)
        // если это стартовый день и он является isSub, то добавляем в день поддни в массив
        // if (el.isSub) {
        //   el.subDays = subDays
        // }

        if (new Date().getTime() > new Date(event.endDate).getTime()) {
          el.disabled = true
        } else {
          el.disabled = false
        }
        el.event = event
        el.isStart = true
      }

      if (isLastDayOfMonth(el.date)) {
        el.isLastDayOfMonth = true
        el.monthName = monthNames[el.date.getMonth()]
        el.year = el.date.getFullYear()
      }

      if (isFirstDayOfMonth(el.date)) {
        el.isFirstDayOfMonth = true
      }
    })
  })

  return objectDates
}
