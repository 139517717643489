import { getPluralMonthName } from './generateDatesWithEvents'

const modifyEvent = (event, idx) => {
  const from = new Date(event.dateFrom)
  const to = new Date(event.dateTo)
  const copyEvent = { ...event }

  if (!event.dateFrom || !event.dateTo) {
    return { ...event }
  }

  if (from.getMonth() === to.getMonth()) {
    if (from.getUTCDate() === to.getUTCDate()) {
      copyEvent.date = `${to.getUTCDate()} ${getPluralMonthName(to)}`
    } else {
      copyEvent.date = `${from.getUTCDate()} - ${to.getUTCDate()} ${getPluralMonthName(to)}`
    }

    copyEvent.tableDate = `${from.getUTCDate()} - ${to.getUTCDate()}.${from.getMonth() + 1 < 10 ? '0' : ''}${from.getMonth() + 1}`
  } else {
    copyEvent.date = `${from.getUTCDate()} ${getPluralMonthName(from)} - ${to.getUTCDate()} ${getPluralMonthName(to)}`
    copyEvent.tableDate = `${from.getUTCDate()}.${from.getMonth() < 10 ? 0 : ''}${from.getMonth() + 1} - ${to.getUTCDate()}.${to.getMonth() < 10 ? '0' : ''}${to.getMonth() + 1}`
  }

  return copyEvent
}

export function formatFromAndToDate (from, to) {
  const fromDate = new Date(from)
  const toDate = new Date(to)

  let date = ''

  if (fromDate.getMonth() === toDate.getMonth()) {
    if (fromDate.getUTCDate() === toDate.getUTCDate()) {
      date = `${toDate.getUTCDate()} ${getPluralMonthName(toDate)}`
    } else {
      date = `${fromDate.getUTCDate()} - ${toDate.getUTCDate()} ${getPluralMonthName(toDate)}`
    }
  } else {
    date = `${fromDate.getUTCDate()} ${getPluralMonthName(fromDate)} - ${toDate.getUTCDate()} ${getPluralMonthName(toDate)}`
  }

  return date
}

export function formatEvents (events) {
  return Array.isArray(events)
    ? events.map((event) => {
      return modifyEvent(event)
    })
    : modifyEvent(events)
}
