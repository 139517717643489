//
//
//
//
//
//
//

export default {
  name: 'EmptyLayout'
}
