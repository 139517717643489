export const META_MAP = {
  home: {
    title: 'Все о Ралли в России: Новости, Команды и Пилоты, Фото и Видео, Онлайн-Результаты, Турнирные Таблицы.',
    description: 'Всё о Ралли в России 2021 от промоутера ASMG. Спортсмены и команды, прямые трансляции, фото и видео, календарь Кубка России и Чемпионата России по Ралли. Онлайн-результаты и турнирные таблицы.'
  },
  news: {
    title: 'Новости Ралли: Последние события Чемпионата и Кубка России по ралли',
    description: 'Самые свежие новости о Ралли 2021 от промоутера ASMG. Спортсмены, прямые трансляции, фото и видео, календарь гонок Кубка России и Чемпионата России по Ралли. Онлайн-результаты и турнирные таблицы.'
  },
  tournament: {
    title: 'Результаты Ралли: Турнирная Таблица Чемпионата и Кубка России',
    description: 'Всё о Ралли в России 2021 от промоутера ASMG. Онлайн-результаты и турнирные таблицы, спортсмены, прямые трансляции, фото и видео, календарь Кубка России и Чемпионата России по Ралли.'
  },
  pilots: {
    title: 'Пилоты Чемпионата и Кубка России по Ралли',
    description: 'Всё о Ралли в России 2021 от промоутера ASMG. Пилоты и штурманы, прямые трансляции, фото и видео, календарь Кубка России и Чемпионата России по Ралли. Онлайн-результаты и турнирные таблицы'
  },
  navigators: {
    title: 'Штурманы Чемпионата и Кубка России по Ралли',
    description: 'Всё о Ралли в России 2021 от промоутера ASMG. Пилоты и штурманы, прямые трансляции, фото и видео, календарь Кубка России и Чемпионата России по Ралли. Онлайн-результаты и турнирные таблицы.'
  },
  teams: {
    title: 'Команды Чемпионата и Кубка России по Ралли',
    description: 'Всё о Ралли в России 2021 от промоутера ASMG. Команды, пилоты и штурманы, фото и видео, календарь Кубка России и Чемпионата России по Ралли. Онлайн-результаты и турнирные таблицы.'
  },
  calendar: {
    title: 'Календарь Чемпионата и Кубка России по Ралли',
    description: 'Всё о Ралли в России 2021 от промоутера ASMG. Календарь соревнований, онлайн-результаты и турнирные таблицы. Спортсмены и команды, прямые трансляции, фото и видео.'
  },
  sponsors: {
    title: 'Сотрудничество и Спонсорство в Чемпионате и Кубке России по Ралли',
    description: ' Реклама в ралли от промоутера ASMG. Свежие новости о ралли в России. Прямые трансляции, фото и видео, календарь соревнований, онлайн-результаты и турнирные таблицы.'
  },
  media: {
    title: 'Фото и Видео Соревнований Чемпионата и Кубка России по Ралли',
    description: 'Всё о Ралли в России 2021 от промоутера ASMG. Фото и видео, прямые трансляции, спортсмены и команды, календарь Кубка России и Чемпионата России, результаты гонок.'
  },
  about: {
    title: 'Ралли Промоутер ASMG: Продвижение и развитие Ралли в России',
    description: 'Последние новости Ралли 2021 от промоутера ASMG.Прямые трансляции, фото и видео, календари соревнований, онлайн-результаты гонок и турнирные таблицы.'
  },
  policy: {
    title: 'Ралли Промоутер ASMG: Политика конфиденциальности',
    description: 'Всё о Ралли в России 2021 от промоутера ASMG. Спортсмены и команды, трансляции, медиа, календарь соревнований по Ралли. Онлайн-результаты и турнирные таблицы.'
  },
  privacy: {
    title: 'Ралли Промоутер ASMG: Пользовательское соглашение',
    description: 'Всё о Ралли в России 2021 от промоутера ASMG. Спортсмены и команды, трансляции, медиа, календарь соревнований по Ралли. Онлайн-результаты и турнирные таблицы.'
  }
}
