//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import HeadlineArrow from '@/assets/svg/button-arrow--red.svg?inline'

export default {
  components: {
    HeadlineArrow
  },
  layout: 'default',
  props: ['error']
}
