//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import Logo from '@/assets/svg/asmg.svg?inline'
import SearchIcon from '@/assets/svg/Search.svg?inline'
import MenuIcon from '@/assets/svg/Menu.svg?inline'
import CloseMenuIcon from '@/assets/svg/CloseMenu.svg?inline'
// import ProfileIcon from '@/assets/svg/profile.svg?inline'
import { AUTH_ROUTES } from '~/constants'

export default {
  name: 'Header',
  components: {
    Logo,
    SearchIcon,
    MenuIcon,
    CloseMenuIcon
    // ProfileIcon
  },
  props: {
    transparent: {
      type: Boolean,
      default: false
    },
    light: {
      type: Boolean,
      default: false
    },
    filled: {
      type: Boolean,
      default: false
    },
    darkLogo: {
      type: Boolean,
      default: false
    },
    haveBanners: {
      type: Boolean,
      default: false
    },
    bannerUnfold: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      searchOpen: false,
      isMenuOpen: false,
      links: [
        {
          path: '/news',
          text: 'Новости'
        },
        {
          path: '/tournament-table',
          text: 'Турнирная таблица'
        },
        {
          path: '/pilots',
          text: 'Пилоты и команды'
        },
        {
          path: '/calendar',
          text: 'Календарь'
        },
        {
          path: '/sponsorship',
          text: 'Стать спонсором'
        },
        {
          path: '/media',
          text: 'Медиа'
        },
        {
          path: '/partners',
          text: 'Партнеры'
        },
        {
          path: '/about',
          text: 'О нас'
        }
      ]
    }
  },
  computed: {
    ...mapState({
      loggedIn: state => state.auth.loggedIn
    }),
    isDarkButton () {
      return this.loggedIn || AUTH_ROUTES.includes(this.$route.path)
    },
    isMobile () {
      const windowWidth = window.innerWidth
      const smSize = 768

      return windowWidth < smSize
    }
  },
  watch: {
    '$route' () {
      if (this.isMenuOpen) {
        this.isMenuOpen = false
      }
    }
  },
  methods: {
    openSearch () {
      this.searchOpen = true
      this.isMenuOpen = false
    },
    toggleMenu () {
      this.isMenuOpen = !this.isMenuOpen
    },
    toLogin () {
      let path = '/login'

      if (this.loggedIn) {
        path = '/profile'
      }

      this.$router.push(path)
    }
  }
}
