//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Logo from '@/assets/svg/asmg-footer.svg?inline'

import Youtube from '@/assets/svg/social/youtube.svg?inline'
import Telegram from '@/assets/svg/social/telegram-dark.svg?inline'
import Vkontakte from '@/assets/svg/social/vk.svg?inline'

export default {
  components: {
    Logo,
    Youtube,
    Telegram,
    Vkontakte
  },
  computed: {
    getFullYear: () => new Date().getUTCFullYear()
  }
}
