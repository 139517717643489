import { format } from 'date-fns'
import qs from 'qs'
import { formatEvents } from '~/utils/formatEvents'
import { getSeason } from '~/utils/generateDatesWithEvents'

export const state = () => ({
  teams: [],
  members: [],
  originalEvents: [],
  comingEvents: [],
  events: [],
  albums: [],
  disciplines: [],
  allNews: [],
  news: {
    data: [],
    total: 0
  },
  newsFilters: {
    seasons: [],
    tournaments: []
  },
  headerWhite: false,
  isCookiesApprove: false,
  isLoading: false,
  actualEvent: null,
  foldedBanner: true,
  isActualEventLoading: true
})

export const mutations = {
  SET_FOLDED (state, value) {
    state.foldedBanner = value
  },
  SET_LOADING (state, isLoading) {
    state.isLoading = isLoading
  },
  SET_MEMBERS (state, members) {
    state.members = members
  },
  SET_TEAMS (state, teams) {
    state.teams = teams
  },
  SET_EVENTS (state, events) {
    state.events = events
  },
  SET_ORIGINAL_EVENTS (state, events) {
    state.originalEvents = events
  },
  SET_COMING_EVENTS (state, comingEvents) {
    state.comingEvents = comingEvents
  },
  SET_HEADER_WHITE (state, isWhite) {
    state.headerWhite = isWhite
  },
  SET_ALBUMS (state, albums) {
    state.albums = albums
  },
  SET_DISCIPLINES (state, disciplines) {
    state.disciplines = disciplines
  },
  SET_APPROVE_COOKIES (state) {
    state.isCookiesApprove = true
  },
  SET_ALL_NEWS (state, allNews) {
    state.allNews = allNews
  },
  SET_NEWS (state, news) {
    state.news = news
  },
  SET_ACTUAL_EVENT (state, event) {
    state.actualEvent = event
    state.isActualEventLoading = false
  },
  UPDATE_PROFILE_DOCUMENTS (state, updatedDocuments) {
    state.auth.user.papersScans = updatedDocuments
  },
  UPDATE_PROFILE_DOCUMENT_NAME (state, renamedDocumentId, newFileName) {
    state.auth.user?.papersScans?.forEach(
      (document) => {
        if (document.id !== renamedDocumentId) {
          document.name = newFileName
        }
      }
    )

    state.documentsDialogs.rename = null
  },
  SET_PROFILE_AVATAR (state, avatarPath) {
    state.auth.user.info.avatar = avatarPath
  }
}

export const actions = {
  setFolded ({ commit }, value) {
    commit('SET_FOLDED', value)
  },
  async setMembers ({ commit }, team) {
    const members = await this.$axios.$get(
      `/members${team ? '?team=' + team : ''}`
    )

    const modifyMembers = members.map((member) => {
      return {
        ...member,
        fullName: `${member.firstName} ${member.lastName}`
      }
    })

    commit('SET_MEMBERS', modifyMembers)
  },
  async setEvents ({ commit }, params) {
    let events

    if (!params) {
      events = await this.$axios.$get('/events')
    } else {
      events = await this.$axios.$get(`/events?${qs.stringify(params)}`)
    }

    const season = getSeason(events)
    const filteredBySeason = events.filter((event) => {
      return event.season === season
    })
    const formattedEvents = formatEvents(filteredBySeason)

    commit('SET_EVENTS', formattedEvents)
    commit('SET_ORIGINAL_EVENTS', formattedEvents)
  },
  filterEvents ({ state, commit }, tab) {
    if (tab === 'default') {
      commit('SET_EVENTS', formatEvents(state.originalEvents))

      return
    }

    const filtered = state.originalEvents.filter((event) => {
      if (event.tournament) {
        return event.tournament.short === tab
      }
      return false
    })

    commit('SET_EVENTS', formatEvents(filtered))
  },
  async setComingEvents ({ commit }) {
    const todayDate = format(new Date(), 'yyyy-MM-dd')

    const comingEvents = await this.$axios.$get(
      `/events/coming?limit=2&dateFrom=${todayDate}`
    )

    commit('SET_COMING_EVENTS', formatEvents(comingEvents))
  },
  async setDisciplines ({ commit }) {
    const disciplines = await this.$axios.$get('/disciplines')

    commit('SET_DISCIPLINES', disciplines)
  },
  async setTeams ({ commit }) {
    await commit('SET_LOADING', true)
    const members = await this.$axios.$get('/members')
    const teams = await this.$axios.$get('/teams')

    const modifyTeams = teams.map((team) => {
      const teamMembers = members.filter((member) => {
        if (!member.team) {
          return null
        }

        return member.team.id === team.id
      })

      return {
        ...team,
        members: teamMembers
      }
    })
    await commit('SET_LOADING', false)
    commit('SET_TEAMS', modifyTeams)
  },
  setHeaderWhite ({ commit }, isWhite) {
    commit('SET_HEADER_WHITE', isWhite)
  },
  setApproveCookies ({ commit }) {
    commit('SET_APPROVE_COOKIES')
  },
  async setAlbums ({ commit }) {
    const albums = await this.$axios.$get('/media/albums')

    commit('SET_ALBUMS', albums)
  },
  async setAllNews ({ commit, state }) {
    if (state.allNews.length) {
      return
    }

    const allNews = await this.$axios.$get('/news')
    commit('SET_ALL_NEWS', allNews)
  },
  async setNews ({ commit }, { page, ...params } = {}) {
    const newsUrl = '/news'
    const start = page ? page * params.size - params.size : undefined
    const queryParams = params
      ? qs.stringify({ start, ...params }, { encode: false })
      : null
    const queryUrl = queryParams ? `${newsUrl}?${queryParams}` : newsUrl

    const news = await this.$axios.$get(queryUrl)

    commit('SET_NEWS', {
      data: news.results ?? news,
      total: news.total
    })
  },
  updateProfileDocuments ({ commit, state }, deletedDocumentId) {
    const filteredDocuments = state.auth.user?.papersScans?.filter(
      document => document.id !== deletedDocumentId
    )

    commit('UPDATE_PROFILE_DOCUMENTS', filteredDocuments)
  },
  updateProfileDocumentName ({ commit }, updatedDocuments) {
    commit('UPDATE_PROFILE_DOCUMENTS', updatedDocuments)
  },
  async getActualEvent ({ commit }) {
    try {
      const actualEvent = await this.$axios.get('/events/actual')
      const data = {
        ...actualEvent.data
      }

      commit('SET_ACTUAL_EVENT', data)
    } catch (error) {
    }
  },
  setProfileAvatar ({ commit }, avatarPath) {
    commit('SET_PROFILE_AVATAR', avatarPath)
  }
}

export const getters = {
  navigators: state =>
    state.members.filter(member =>
      member.role.find(el => el.slug === 'Navigator')
    ),
  pilots: state =>
    state.members.filter(member => member.role.find(el => el.slug === 'Pilot')),
  isCurrentEvent: state => state.actualEvent?.type === 'current',
  albums: state => state.albums,
  news: state =>
    state.news.data &&
    state.news.data.map(
      ({
        id,
        slug,
        subtitle,
        newsPicUrl,
        publicationDate,
        tags,
        title,
        createdAt,
        text,
        season,
        tournament,
        ...rest
      }) => ({
        id,
        img: newsPicUrl,
        date: publicationDate,
        title,
        tags: tags.length ? tags[0].split(',') : [],
        content: text,
        season,
        tournament,
        subtitle,
        slug: slug || id,
        ...rest
      })
    ),
  totalNews: state => state.allNews.length,
  allNews: state =>
    state.allNews &&
    state.allNews.map(
      ({
        id,
        slug,
        subtitle,
        newsPicUrl,
        tags,
        publicationDate,
        title,
        createdAt,
        text,
        season,
        tournament,
        ...rest
      }) => ({
        id,
        img: newsPicUrl,
        date: publicationDate,
        title,
        tags: tags.length ? tags[0].split(',') : [],
        content: text,
        season,
        tournament,
        subtitle,
        slug: slug || id,
        ...rest
      })
    ),
  getNewsFilters (state) {
    const years = state.allNews.map(({ season }) => season)
    years.unshift('Все года')
    const seasons = Array.from(new Set(years), (item, index) => ({
      id: index + 1,
      name: item
    }))

    const types = state.allNews.map(({ tournament }) => tournament)
    const tournaments = []
    const checkIncludes = id => tournaments.find(item => item.id === id)

    types.forEach((item) => {
      if (
        item &&
        typeof item === 'object' &&
        checkIncludes(item.id) === undefined
      ) {
        tournaments.push(item)
      }
    })

    return {
      seasons,
      tournaments
    }
  },
  papersScans: state => state.auth?.user?.papersScans,
  foldedStatus: state => state.foldedBanner,
  userRaces: (state) => {
    const processedRaces = state.auth?.user?.races.map((event) => {
      const {
        id,
        title,
        stage,
        dateFrom,
        city,
        details,
        tournamentStageString
      } = event.event
      const { status } = event

      const lowerCaseStatus = status?.toLowerCase()

      return {
        id,
        title,
        stage,
        dateFrom,
        city,
        backgroundImage: details?.backgroundImage,
        tournamentStageString,
        specialStagesCount: details?.specialStagesCount,
        status: lowerCaseStatus
      }
    })

    return processedRaces
  }
}
