export default (ctx) => {
  const { store, redirect, from } = ctx

  if (!store.state.auth.loggedIn) {
    return
  }

  const authRoutes = ['/register', '/login', '/forgot']

  if (from && authRoutes.includes(from.path)) {
    redirect('/profile')
  } else {
    redirect(from.path)
  }
}
