//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Logo from '@/assets/svg/asmg.svg?inline'
// import Twitter from '@/assets/svg/social/Twitter.svg?inline'

import SearchIcon from '@/assets/svg/Search.svg?inline'
import MenuIcon from '@/assets/svg/Menu.svg?inline'
import CloseMenuIcon from '@/assets/svg/CloseMenu.svg?inline'

export default {
  name: 'AlbumLayout',
  components: {
    Logo,
    // Twitter,
    SearchIcon,
    MenuIcon,
    CloseMenuIcon
  },
  data () {
    return {
      isMobileMenuOpen: false,
      showSearch: false
    }
  },
  watch: {
    '$route' () {
      this.isMobileMenuOpen = false
      this.showSearch = false
    }
  },
  methods: {
    searchToggle () {
      this.showSearch = !this.showSearch
    }
  }
}
