import Vue from 'vue'
import { wrapFunctional } from './utils'

const components = {
  Button: () => import('../../components/base/Button.vue' /* webpackChunkName: "components/button" */).then(c => wrapFunctional(c.default || c)),
  CookiesApprove: () => import('../../components/base/CookiesApprove.vue' /* webpackChunkName: "components/cookies-approve" */).then(c => wrapFunctional(c.default || c)),
  CustomButton: () => import('../../components/base/CustomButton.vue' /* webpackChunkName: "components/custom-button" */).then(c => wrapFunctional(c.default || c)),
  Dropdown: () => import('../../components/base/Dropdown.vue' /* webpackChunkName: "components/dropdown" */).then(c => wrapFunctional(c.default || c)),
  EventHeader: () => import('../../components/base/EventHeader.vue' /* webpackChunkName: "components/event-header" */).then(c => wrapFunctional(c.default || c)),
  Filters: () => import('../../components/base/Filters.vue' /* webpackChunkName: "components/filters" */).then(c => wrapFunctional(c.default || c)),
  Headline: () => import('../../components/base/Headline.vue' /* webpackChunkName: "components/headline" */).then(c => wrapFunctional(c.default || c)),
  Pagination: () => import('../../components/base/Pagination.vue' /* webpackChunkName: "components/pagination" */).then(c => wrapFunctional(c.default || c)),
  Search: () => import('../../components/base/Search.vue' /* webpackChunkName: "components/search" */).then(c => wrapFunctional(c.default || c)),
  SearchMember: () => import('../../components/base/SearchMember.vue' /* webpackChunkName: "components/search-member" */).then(c => wrapFunctional(c.default || c)),
  TableTabs: () => import('../../components/base/TableTabs.vue' /* webpackChunkName: "components/table-tabs" */).then(c => wrapFunctional(c.default || c)),
  TranslationLink: () => import('../../components/base/TranslationLink.vue' /* webpackChunkName: "components/translation-link" */).then(c => wrapFunctional(c.default || c)),
  Broadcast: () => import('../../components/screens/Broadcast.vue' /* webpackChunkName: "components/broadcast" */).then(c => wrapFunctional(c.default || c)),
  DummyPlug: () => import('../../components/screens/DummyPlug.vue' /* webpackChunkName: "components/dummy-plug" */).then(c => wrapFunctional(c.default || c)),
  HighlightedLastEvent: () => import('../../components/screens/HighlightedLastEvent.vue' /* webpackChunkName: "components/highlighted-last-event" */).then(c => wrapFunctional(c.default || c)),
  PilotsTeamsStandings: () => import('../../components/screens/PilotsTeamsStandings.vue' /* webpackChunkName: "components/pilots-teams-standings" */).then(c => wrapFunctional(c.default || c)),
  RaceResultsTable: () => import('../../components/screens/RaceResultsTable.vue' /* webpackChunkName: "components/race-results-table" */).then(c => wrapFunctional(c.default || c)),
  TheCalendar: () => import('../../components/screens/TheCalendar.vue' /* webpackChunkName: "components/the-calendar" */).then(c => wrapFunctional(c.default || c)),
  TheFooter: () => import('../../components/screens/TheFooter.vue' /* webpackChunkName: "components/the-footer" */).then(c => wrapFunctional(c.default || c)),
  TheMedia: () => import('../../components/screens/TheMedia.vue' /* webpackChunkName: "components/the-media" */).then(c => wrapFunctional(c.default || c)),
  TheSponsorship: () => import('../../components/screens/TheSponsorship.vue' /* webpackChunkName: "components/the-sponsorship" */).then(c => wrapFunctional(c.default || c)),
  TheStartScreen: () => import('../../components/screens/TheStartScreen.vue' /* webpackChunkName: "components/the-start-screen" */).then(c => wrapFunctional(c.default || c)),
  TheSubscription: () => import('../../components/screens/TheSubscription.vue' /* webpackChunkName: "components/the-subscription" */).then(c => wrapFunctional(c.default || c)),
  ActiveRaceInfo: () => import('../../components/screens/ActiveRaceInfo/index.vue' /* webpackChunkName: "components/active-race-info" */).then(c => wrapFunctional(c.default || c)),
  TheNewsBannerNews: () => import('../../components/screens/TheNews/BannerNews.vue' /* webpackChunkName: "components/the-news-banner-news" */).then(c => wrapFunctional(c.default || c)),
  TheNewsCard: () => import('../../components/screens/TheNews/Card.vue' /* webpackChunkName: "components/the-news-card" */).then(c => wrapFunctional(c.default || c)),
  TheNewsCentralNews: () => import('../../components/screens/TheNews/CentralNews.vue' /* webpackChunkName: "components/the-news-central-news" */).then(c => wrapFunctional(c.default || c)),
  TheNewsWithBanner: () => import('../../components/screens/TheNews/NewsWithBanner.vue' /* webpackChunkName: "components/the-news-with-banner" */).then(c => wrapFunctional(c.default || c)),
  TheNews: () => import('../../components/screens/TheNews/index.vue' /* webpackChunkName: "components/the-news" */).then(c => wrapFunctional(c.default || c)),
  TheNewsDetailsShare: () => import('../../components/screens/TheNewsDetails/Share.vue' /* webpackChunkName: "components/the-news-details-share" */).then(c => wrapFunctional(c.default || c)),
  TheNewsDetails: () => import('../../components/screens/TheNewsDetails/index.vue' /* webpackChunkName: "components/the-news-details" */).then(c => wrapFunctional(c.default || c)),
  TheQuiz: () => import('../../components/screens/TheQuiz/index.vue' /* webpackChunkName: "components/the-quiz" */).then(c => wrapFunctional(c.default || c)),
  TheQuizQuestion: () => import('../../components/screens/TheQuiz/question.vue' /* webpackChunkName: "components/the-quiz-question" */).then(c => wrapFunctional(c.default || c)),
  TheSearch: () => import('../../components/screens/TheSearch/index.vue' /* webpackChunkName: "components/the-search" */).then(c => wrapFunctional(c.default || c)),
  TheNewsDetailsOtherCard: () => import('../../components/screens/TheNewsDetails/Other/Card.vue' /* webpackChunkName: "components/the-news-details-other-card" */).then(c => wrapFunctional(c.default || c)),
  TheNewsDetailsOther: () => import('../../components/screens/TheNewsDetails/Other/index.vue' /* webpackChunkName: "components/the-news-details-other" */).then(c => wrapFunctional(c.default || c)),
  ActiveRaceTable: () => import('../../components/tables/ActiveRaceTable.vue' /* webpackChunkName: "components/active-race-table" */).then(c => wrapFunctional(c.default || c)),
  CalendarTable: () => import('../../components/tables/CalendarTable.vue' /* webpackChunkName: "components/calendar-table" */).then(c => wrapFunctional(c.default || c)),
  CurrentEventTable: () => import('../../components/tables/CurrentEventTable.vue' /* webpackChunkName: "components/current-event-table" */).then(c => wrapFunctional(c.default || c)),
  DeclaredCrewTable: () => import('../../components/tables/DeclaredCrewTable.vue' /* webpackChunkName: "components/declared-crew-table" */).then(c => wrapFunctional(c.default || c)),
  EventTable: () => import('../../components/tables/EventTable.vue' /* webpackChunkName: "components/event-table" */).then(c => wrapFunctional(c.default || c)),
  Highlighted: () => import('../../components/tables/Highlighted.vue' /* webpackChunkName: "components/highlighted" */).then(c => wrapFunctional(c.default || c)),
  LastEvent: () => import('../../components/tables/LastEvent.vue' /* webpackChunkName: "components/last-event" */).then(c => wrapFunctional(c.default || c)),
  Standings: () => import('../../components/tables/Standings.vue' /* webpackChunkName: "components/standings" */).then(c => wrapFunctional(c.default || c)),
  TournamentTableConstants: () => import('../../components/tables/TournamentTable/constants.js' /* webpackChunkName: "components/tournament-table-constants" */).then(c => wrapFunctional(c.default || c)),
  TournamentTable: () => import('../../components/tables/TournamentTable/index.vue' /* webpackChunkName: "components/tournament-table" */).then(c => wrapFunctional(c.default || c)),
  LastTranslations: () => import('../../components/sliders/LastTranslations/index.vue' /* webpackChunkName: "components/last-translations" */).then(c => wrapFunctional(c.default || c)),
  PilotsSliderItem: () => import('../../components/sliders/PilotsTeams/PilotsSliderItem.vue' /* webpackChunkName: "components/pilots-slider-item" */).then(c => wrapFunctional(c.default || c)),
  PilotsTeams: () => import('../../components/sliders/PilotsTeams/PilotsTeams.vue' /* webpackChunkName: "components/pilots-teams" */).then(c => wrapFunctional(c.default || c)),
  SwiperModalSlide: () => import('../../components/sliders/SwiperModal/Slide.vue' /* webpackChunkName: "components/swiper-modal-slide" */).then(c => wrapFunctional(c.default || c)),
  SwiperModal: () => import('../../components/sliders/SwiperModal/index.vue' /* webpackChunkName: "components/swiper-modal" */).then(c => wrapFunctional(c.default || c)),
  AlbumItem: () => import('../../components/media/AlbumItem.vue' /* webpackChunkName: "components/album-item" */).then(c => wrapFunctional(c.default || c)),
  MediaItem: () => import('../../components/media/MediaItem.vue' /* webpackChunkName: "components/media-item" */).then(c => wrapFunctional(c.default || c)),
  Announcements: () => import('../../components/Announcements.vue' /* webpackChunkName: "components/announcements" */).then(c => wrapFunctional(c.default || c)),
  FeedbackForm: () => import('../../components/FeedbackForm.vue' /* webpackChunkName: "components/feedback-form" */).then(c => wrapFunctional(c.default || c)),
  Header: () => import('../../components/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c)),
  Modal: () => import('../../components/Modal.vue' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c)),
  Sponsors: () => import('../../components/Sponsors.vue' /* webpackChunkName: "components/sponsors" */).then(c => wrapFunctional(c.default || c)),
  Translations: () => import('../../components/Translations.vue' /* webpackChunkName: "components/translations" */).then(c => wrapFunctional(c.default || c)),
  YoutubeFrame: () => import('../../components/YoutubeFrame.vue' /* webpackChunkName: "components/youtube-frame" */).then(c => wrapFunctional(c.default || c)),
  AuthLayout: () => import('../../components/Auth/Layout.vue' /* webpackChunkName: "components/auth-layout" */).then(c => wrapFunctional(c.default || c)),
  CalendarTimeline: () => import('../../components/CalendarTimeline/CalendarTimeline.vue' /* webpackChunkName: "components/calendar-timeline" */).then(c => wrapFunctional(c.default || c)),
  MembersPageLayout: () => import('../../components/MembersPageLayout/index.vue' /* webpackChunkName: "components/members-page-layout" */).then(c => wrapFunctional(c.default || c)),
  MembersHeader: () => import('../../components/MembersPageLayout/membersHeader.vue' /* webpackChunkName: "components/members-header" */).then(c => wrapFunctional(c.default || c)),
  CardMember: () => import('../../components/card/Member.vue' /* webpackChunkName: "components/card-member" */).then(c => wrapFunctional(c.default || c)),
  CardTeam: () => import('../../components/card/Team.vue' /* webpackChunkName: "components/card-team" */).then(c => wrapFunctional(c.default || c)),
  EventDescription: () => import('../../components/event/description.vue' /* webpackChunkName: "components/event-description" */).then(c => wrapFunctional(c.default || c)),
  EventDocuments: () => import('../../components/event/documents.vue' /* webpackChunkName: "components/event-documents" */).then(c => wrapFunctional(c.default || c)),
  EventNews: () => import('../../components/event/news.vue' /* webpackChunkName: "components/event-news" */).then(c => wrapFunctional(c.default || c)),
  EventPhotos: () => import('../../components/event/photos.vue' /* webpackChunkName: "components/event-photos" */).then(c => wrapFunctional(c.default || c)),
  EventResults: () => import('../../components/event/results.vue' /* webpackChunkName: "components/event-results" */).then(c => wrapFunctional(c.default || c)),
  EventShedule: () => import('../../components/event/shedule.vue' /* webpackChunkName: "components/event-shedule" */).then(c => wrapFunctional(c.default || c)),
  EventTextTranslation: () => import('../../components/event/text-translation.vue' /* webpackChunkName: "components/event-text-translation" */).then(c => wrapFunctional(c.default || c)),
  EventVideoTranslation: () => import('../../components/event/video-translation.vue' /* webpackChunkName: "components/event-video-translation" */).then(c => wrapFunctional(c.default || c)),
  FormForgotPassword: () => import('../../components/form/ForgotPassword.vue' /* webpackChunkName: "components/form-forgot-password" */).then(c => wrapFunctional(c.default || c)),
  FormGroup: () => import('../../components/form/Group.vue' /* webpackChunkName: "components/form-group" */).then(c => wrapFunctional(c.default || c)),
  FormLogin: () => import('../../components/form/Login.vue' /* webpackChunkName: "components/form-login" */).then(c => wrapFunctional(c.default || c)),
  FormPhoneInput: () => import('../../components/form/PhoneInput.vue' /* webpackChunkName: "components/form-phone-input" */).then(c => wrapFunctional(c.default || c)),
  FormSmsCode: () => import('../../components/form/SmsCode.vue' /* webpackChunkName: "components/form-sms-code" */).then(c => wrapFunctional(c.default || c)),
  FormSuccessTemplate: () => import('../../components/form/SuccessTemplate.vue' /* webpackChunkName: "components/form-success-template" */).then(c => wrapFunctional(c.default || c)),
  NewsCard: () => import('../../components/news/NewsCard.vue' /* webpackChunkName: "components/news-card" */).then(c => wrapFunctional(c.default || c)),
  NewsSection: () => import('../../components/news/NewsSection.vue' /* webpackChunkName: "components/news-section" */).then(c => wrapFunctional(c.default || c)),
  ProfileDocumentIcon: () => import('../../components/profile/DocumentIcon.vue' /* webpackChunkName: "components/profile-document-icon" */).then(c => wrapFunctional(c.default || c)),
  ProfileInfo: () => import('../../components/profile/Info.vue' /* webpackChunkName: "components/profile-info" */).then(c => wrapFunctional(c.default || c)),
  ProfileRaces: () => import('../../components/profile/Races.vue' /* webpackChunkName: "components/profile-races" */).then(c => wrapFunctional(c.default || c)),
  EventCrewsCrew: () => import('../../components/event/crews/crew.vue' /* webpackChunkName: "components/event-crews-crew" */).then(c => wrapFunctional(c.default || c)),
  EventCrews: () => import('../../components/event/crews/index.vue' /* webpackChunkName: "components/event-crews" */).then(c => wrapFunctional(c.default || c)),
  EventInfo: () => import('../../components/event/info/index.vue' /* webpackChunkName: "components/event-info" */).then(c => wrapFunctional(c.default || c)),
  EventInfoParams: () => import('../../components/event/info/params.vue' /* webpackChunkName: "components/event-info-params" */).then(c => wrapFunctional(c.default || c)),
  FormRegister: () => import('../../components/form/Register/index.vue' /* webpackChunkName: "components/form-register" */).then(c => wrapFunctional(c.default || c)),
  ProfileChat: () => import('../../components/profile/Chat/index.vue' /* webpackChunkName: "components/profile-chat" */).then(c => wrapFunctional(c.default || c)),
  ProfileDocumentsDialog: () => import('../../components/profile/Documents/Dialog.vue' /* webpackChunkName: "components/profile-documents-dialog" */).then(c => wrapFunctional(c.default || c)),
  ProfileDocumentsList: () => import('../../components/profile/Documents/List.vue' /* webpackChunkName: "components/profile-documents-list" */).then(c => wrapFunctional(c.default || c)),
  ProfileDocuments: () => import('../../components/profile/Documents/index.vue' /* webpackChunkName: "components/profile-documents" */).then(c => wrapFunctional(c.default || c)),
  ProfileDocumentsOptions: () => import('../../components/profile/Documents/options.vue' /* webpackChunkName: "components/profile-documents-options" */).then(c => wrapFunctional(c.default || c)),
  ProfileMobileTabs: () => import('../../components/profile/MobileTabs/index.vue' /* webpackChunkName: "components/profile-mobile-tabs" */).then(c => wrapFunctional(c.default || c)),
  ProfileTabs: () => import('../../components/profile/Tabs/index.vue' /* webpackChunkName: "components/profile-tabs" */).then(c => wrapFunctional(c.default || c)),
  ProfileDocumentsItem: () => import('../../components/profile/Documents/Item/index.vue' /* webpackChunkName: "components/profile-documents-item" */).then(c => wrapFunctional(c.default || c)),
  ProfileDocumentsItemProps: () => import('../../components/profile/Documents/Item/props.js' /* webpackChunkName: "components/profile-documents-item-props" */).then(c => wrapFunctional(c.default || c))
}

for (const name in components) {
  Vue.component(name, components[name])
  Vue.component('Lazy' + name, components[name])
}
