import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _d8a8f054 = () => interopDefault(import('../pages/about/index.vue' /* webpackChunkName: "pages/about/index" */))
const _376d3038 = () => interopDefault(import('../pages/album/index.vue' /* webpackChunkName: "pages/album/index" */))
const _9f0187b0 = () => interopDefault(import('../pages/calendar.vue' /* webpackChunkName: "pages/calendar" */))
const _6031159f = () => interopDefault(import('../pages/current-crew.vue' /* webpackChunkName: "pages/current-crew" */))
const _554ad60d = () => interopDefault(import('../pages/forgot.vue' /* webpackChunkName: "pages/forgot" */))
const _32aaf97f = () => interopDefault(import('../pages/indexold.vue' /* webpackChunkName: "pages/indexold" */))
const _10ba8d22 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _449cf72c = () => interopDefault(import('../pages/media.vue' /* webpackChunkName: "pages/media" */))
const _029f4666 = () => interopDefault(import('../pages/navigators.vue' /* webpackChunkName: "pages/navigators" */))
const _076ad200 = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _1c6abc50 = () => interopDefault(import('../pages/partners/index.vue' /* webpackChunkName: "pages/partners/index" */))
const _b218d0b6 = () => interopDefault(import('../pages/pilots.vue' /* webpackChunkName: "pages/pilots" */))
const _730a0ec8 = () => interopDefault(import('../pages/policy.vue' /* webpackChunkName: "pages/policy" */))
const _37e8fae4 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _d5f21fa2 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _510578bf = () => interopDefault(import('../pages/quiz.vue' /* webpackChunkName: "pages/quiz" */))
const _5fba350d = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _3d268e12 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _c2c11d48 = () => interopDefault(import('../pages/sponsorship.vue' /* webpackChunkName: "pages/sponsorship" */))
const _54aee53c = () => interopDefault(import('../pages/teams.vue' /* webpackChunkName: "pages/teams" */))
const _084d7152 = () => interopDefault(import('../pages/tournament-table/index.vue' /* webpackChunkName: "pages/tournament-table/index" */))
const _9a968c68 = () => interopDefault(import('../pages/translations.vue' /* webpackChunkName: "pages/translations" */))
const _7abb7702 = () => interopDefault(import('../pages/about/contacts.vue' /* webpackChunkName: "pages/about/contacts" */))
const _5ac4e798 = () => interopDefault(import('../pages/about/history.vue' /* webpackChunkName: "pages/about/history" */))
const _667067d4 = () => interopDefault(import('../pages/about/interaction.vue' /* webpackChunkName: "pages/about/interaction" */))
const _4361f5e8 = () => interopDefault(import('../pages/about/media.vue' /* webpackChunkName: "pages/about/media" */))
const _0d76d373 = () => interopDefault(import('../pages/about/site.vue' /* webpackChunkName: "pages/about/site" */))
const _5f96feac = () => interopDefault(import('../pages/about/socials.vue' /* webpackChunkName: "pages/about/socials" */))
const _134076ff = () => interopDefault(import('../pages/about/video.vue' /* webpackChunkName: "pages/about/video" */))
const _409213b2 = () => interopDefault(import('../pages/event/results/_id.vue' /* webpackChunkName: "pages/event/results/_id" */))
const _830c3ec0 = () => interopDefault(import('../pages/album/_id.vue' /* webpackChunkName: "pages/album/_id" */))
const _7567b1f2 = () => interopDefault(import('../pages/broadcast/_id.vue' /* webpackChunkName: "pages/broadcast/_id" */))
const _37fd0c6a = () => interopDefault(import('../pages/event/_id.vue' /* webpackChunkName: "pages/event/_id" */))
const _150295af = () => interopDefault(import('../pages/member/_id.vue' /* webpackChunkName: "pages/member/_id" */))
const _67925968 = () => interopDefault(import('../pages/news/_id.vue' /* webpackChunkName: "pages/news/_id" */))
const _1dd1e6f2 = () => interopDefault(import('../pages/team/_id.vue' /* webpackChunkName: "pages/team/_id" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _d8a8f054,
    name: "about"
  }, {
    path: "/album",
    component: _376d3038,
    name: "album"
  }, {
    path: "/calendar",
    component: _9f0187b0,
    name: "calendar"
  }, {
    path: "/current-crew",
    component: _6031159f,
    name: "current-crew"
  }, {
    path: "/forgot",
    component: _554ad60d,
    name: "forgot"
  }, {
    path: "/indexold",
    component: _32aaf97f,
    name: "indexold"
  }, {
    path: "/login",
    component: _10ba8d22,
    name: "login"
  }, {
    path: "/media",
    component: _449cf72c,
    name: "media"
  }, {
    path: "/navigators",
    component: _029f4666,
    name: "navigators"
  }, {
    path: "/news",
    component: _076ad200,
    name: "news"
  }, {
    path: "/partners",
    component: _1c6abc50,
    name: "partners"
  }, {
    path: "/pilots",
    component: _b218d0b6,
    name: "pilots"
  }, {
    path: "/policy",
    component: _730a0ec8,
    name: "policy"
  }, {
    path: "/privacy",
    component: _37e8fae4,
    name: "privacy"
  }, {
    path: "/profile",
    component: _d5f21fa2,
    name: "profile"
  }, {
    path: "/quiz",
    component: _510578bf,
    name: "quiz"
  }, {
    path: "/register",
    component: _5fba350d,
    name: "register"
  }, {
    path: "/search",
    component: _3d268e12,
    name: "search"
  }, {
    path: "/sponsorship",
    component: _c2c11d48,
    name: "sponsorship"
  }, {
    path: "/teams",
    component: _54aee53c,
    name: "teams"
  }, {
    path: "/tournament-table",
    component: _084d7152,
    name: "tournament-table"
  }, {
    path: "/translations",
    component: _9a968c68,
    name: "translations"
  }, {
    path: "/about/contacts",
    component: _7abb7702,
    name: "about-contacts"
  }, {
    path: "/about/history",
    component: _5ac4e798,
    name: "about-history"
  }, {
    path: "/about/interaction",
    component: _667067d4,
    name: "about-interaction"
  }, {
    path: "/about/media",
    component: _4361f5e8,
    name: "about-media"
  }, {
    path: "/about/site",
    component: _0d76d373,
    name: "about-site"
  }, {
    path: "/about/socials",
    component: _5f96feac,
    name: "about-socials"
  }, {
    path: "/about/video",
    component: _134076ff,
    name: "about-video"
  }, {
    path: "/event/results/:id?",
    component: _409213b2,
    name: "event-results-id"
  }, {
    path: "/album/:id",
    component: _830c3ec0,
    name: "album-id"
  }, {
    path: "/broadcast/:id?",
    component: _7567b1f2,
    name: "broadcast-id"
  }, {
    path: "/event/:id?",
    component: _37fd0c6a,
    name: "event-id"
  }, {
    path: "/member/:id?",
    component: _150295af,
    name: "member-id"
  }, {
    path: "/news/:id",
    component: _67925968,
    name: "news-id"
  }, {
    path: "/team/:id?",
    component: _1dd1e6f2,
    name: "team-id"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
