//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import qs from 'qs'

import SearchIcon from '@/assets/svg/Search.svg?inline'
import CloseSearchIcon from '@/assets/svg/CloseMenu.svg?inline'

import { getSearchResultType } from '@/utils/getSearchResultType'

export default {
  components: {
    SearchIcon,
    CloseSearchIcon
  },
  props: {
    theme: {
      type: String,
      default: 'white'
    },
    placeholder: {
      type: String,
      default: 'Поиск'
    },
    transparent: {
      type: Boolean,
      default: false
    },
    light: {
      type: Boolean,
      default: false
    },
    params: {
      type: Object,
      default: () => {}
    },
    onSearch: () => {},
    role: String,
    mobileMenu: Boolean
  },
  data () {
    return {
      isOpen: false,
      value: '',
      results: [],
      debounce: null,
      limit: 5
    }
  },
  watch: {
    $route () {
      this.results = []
      this.value = ''
    }
  },
  methods: {
    debounceSearch (event) {
      const { value } = event.target

      if (value && value.length > 2) {
        clearTimeout(this.debounce)

        this.debounce = setTimeout(() => {
          this.search(value)
        }, 600)
      } else {
        this.results = []
      }
    },
    toSearchPage (e) {
      e.preventDefault()

      if (!this.value) {
        return
      }

      this.$router.push(`/search?search=${this.value}`)
      this.$emit('close')
    },
    getSearchResult (result) {
      return getSearchResultType(result)
    },
    async search (value) {
      const params = qs.stringify({ ...this.params, role: this.role, search: value || this.value, limit: this.limit })
      const results = await this.$axios.get(`/search?${params}`)

      this.$emit('onSearch', results.data.result)
      this.results = results.data.result
    },
    closeSearch () {
      this.$emit('close')
    }
  }
}
