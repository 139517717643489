export function getSearchResultType (obj) {
  switch (obj.contain) {
    case 'media':
      return {
        name: obj.title,
        link: `/album/${obj.album.event}`
      }
    case 'event':
      return {
        name: obj.title,
        link: obj.externalLink
      }
    case 'member':
      return {
        name: `${obj.firstName} ${obj.lastName}`,
        link: `/member/${obj.id}`
      }
    case 'team':
      return {
        name: obj.name,
        link: `/team/${obj.id}`
      }
    case 'news':
      return {
        name: obj.title,
        link: `/news/${obj.id}`
      }
    default:
      break
  }
}
