export const state = () => ({
  selectDocumentMode: false,
  selectedDocuments: [],
  documentsDialogs: {
    rename: null,
    remove: null
  },
  isLoading: false
})

export const mutations = {
  SET_SELECTED_MODE (state, payload) {
    state.selectDocumentMode = payload
  },
  SET_SELECTED_DOCUMENTS (state, documents) {
    state.selectedDocuments = documents
  },
  SET_DOCUMENTS_DIALOG (state, { dialogName, document }) {
    state.documentsDialogs[dialogName] = document
  },
  SET_LOADING (state, isLoading) {
    state.isLoading = isLoading
  }
}

export const actions = {
  delete ({ commit }, document) {
    commit('SET_LOADING', true)

    return new Promise((resolve, reject) => {
      const { id } = document

      try {
        this.$axios.post('/dashboard/docs/delete', {
          fileId: id
        }).then((res) => {
          if (res.status === 201) {
            resolve(id)

            commit('SET_DOCUMENTS_DIALOG', { dialogName: 'remove', document: null })
          }
        })
      } catch (error) {
        reject(id)
      } finally {
        commit('SET_LOADING', false)
      }
    })
  },
  rename () {

  },
  download (_, downloadedDocument) {
    const { type, fileName, path } = downloadedDocument
    const fileNameWithoutExtension = fileName.split('.')[0]
    const origin = window.location.origin
    const fileLink = `${origin}/${path}`

    const extensionsMimeTypes = {
      jpg: 'image/jpg',
      png: 'image/png',
      jpeg: 'image/jpeg',
      pdf: 'application/pdf',
      docx: 'application/docx',
      xlsx: 'application/xlsx',
      xls: 'application/xls'
    }

    const documentMimeType = extensionsMimeTypes[type]

    return new Promise((resolve) => {
      try {
        this.$axios.get(fileLink, { responseType: 'blob' })
          .then((response) => {
            const blob = new Blob([response.data], { type: documentMimeType })
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = fileNameWithoutExtension
            link.click()
            URL.revokeObjectURL(link.href)
          }).catch(console.error)
      } finally {
        resolve()
      }
    })
  },
  select ({ commit, state }, document) {
    const findedDocument = state.selectedDocuments.find(selectedDocument => selectedDocument.id === document.id)
    const isSelectedDocumentExist = !!findedDocument
    let documents = []

    if (isSelectedDocumentExist) {
      documents = state.selectedDocuments.filter(selectedDocument => selectedDocument.id !== document.id)
    } else {
      documents = [...state.selectedDocuments, document]
    }

    if (documents.length > 0 && !state.selectDocumentMode) {
      commit('SET_SELECTED_MODE', true)
    } else if (documents.length === 0) {
      commit('SET_SELECTED_MODE', false)
    }

    commit('SET_SELECTED_DOCUMENTS', documents)
  },
  setSelectedMode ({ commit }, payload) {
    commit('SET_SELECTED_MODE', payload)
  },
  selectedModeOff ({ commit }) {
    commit('SET_SELECTED_MODE', false)
    commit('SET_SELECTED_DOCUMENTS', [])
  },
  setDocumentsDialog ({ commit }, { dialogName, document }) {
    commit('SET_DOCUMENTS_DIALOG', { dialogName, document })
  }
}
