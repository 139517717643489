import { render, staticRenderFns } from "./light.vue?vue&type=template&id=aa3397e0&scoped=true&"
import script from "./light.vue?vue&type=script&lang=js&"
export * from "./light.vue?vue&type=script&lang=js&"
import style0 from "./light.vue?vue&type=style&index=0&id=aa3397e0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa3397e0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Header: require('/app/components/Header.vue').default,TheFooter: require('/app/components/screens/TheFooter.vue').default})
