//
//
//
//
//
//
//

import HeadlineArrow from '@/assets/svg/button-arrow.svg?inline'

export default {
  components: {
    HeadlineArrow
  },
  props: {
    type: {
      type: String,
      default: 'Кнопка'
    },
    title: {
      type: String,
      default: ''
    },
    theme: {
      type: String,
      default: 'red'
    }
  }
}
