//
//
//
//
//
//
//
//
//

import { META_MAP } from '~/constants/meta'
import Announcements from '~/components/Announcements'
export default {
  name: 'Default',
  head () {
    return {
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: META_MAP.home.description
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: META_MAP.home.title
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: 'https://asmg.ru'
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: META_MAP.home.description
        },
        { hid: 'og:image', property: 'og:image', content: 'https://asmg.ru/start-rally.png' },
        { hid: 'og:image:width', property: 'og:image:width', content: '1200' },
        { hid: 'og:image:height', property: 'og:image:height', content: '630' }
      ]
    }
  },
  components: { Announcements },
  data () {
    return {
      bannerUnfold: false,
      banners: []
    }
  },
  created () {
    this.$axios.get('/announcements/enabled').then((answer) => {
      this.banners = answer.data
    })
  },
  methods: {
    toggleUnfold (value) {
      this.$store.dispatch('setFolded', value)
    }
  },
  computed: {
    isMobile () {
      console.log(window.innerWidth, 380, window.innerWidth < 380)
      const windowWidth = window.innerWidth
      const smSize = 768

      return windowWidth < smSize
    }
  }
}
