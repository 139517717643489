import { render, staticRenderFns } from "./album.vue?vue&type=template&id=766b1b60&scoped=true&"
import script from "./album.vue?vue&type=script&lang=js&"
export * from "./album.vue?vue&type=script&lang=js&"
import style0 from "./album.vue?vue&type=style&index=0&id=766b1b60&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "766b1b60",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Search: require('/app/components/base/Search.vue').default,TheFooter: require('/app/components/screens/TheFooter.vue').default})
